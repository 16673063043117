var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoaded)?_c('v-layout',{attrs:{"column":"","fill-height":"","align-center":"","justify-center":""}},[(_vm.hasError)?_c('AppErrorCard',{attrs:{"entity":"organisational chart","is-anonymous-user":_vm.loggedInUser.isAnonymous,"is-invited":!!_vm.$route.query.invited,"invitee-email":_vm.$route.query.email,"show-auth-loader":_vm.showAuthLoader},on:{"reload":_vm.reloadChartDetails,"login-with-google":function($event){return _vm.$emit('login-with-google')},"login-with-microsoft":function($event){return _vm.$emit('login-with-microsoft')},"login-with-email-pass":function($event){return _vm.$emit('login-with-email-pass', $event)},"signup-with-email-pass":function($event){return _vm.$emit('signup-with-email-pass', $event)},"reset-password":function($event){return _vm.$emit('reset-password', $event)},"update-password":function($event){return _vm.$emit('update-password', $event)}}}):_c('AppLoader')],1):_c('div',[_c('AppPageToolbar',{attrs:{"entity":"organisational chart","entity-name":_vm.meta.name,"visibility":_vm.meta.visibility,"users":_vm.data.users,"domains":_vm.meta.permissions.domains,"logged-in-user":_vm.loggedInUser},on:{"update-name":_vm.updateChartName,"update-privacy":_vm.updatePrivacy,"update-permissions":_vm.updatePermissions,"delete":_vm.deleteChart,"open-import-export-dialog":_vm.openImportExportModal,"set-message":_vm.setMessage}},[_c('div',{staticClass:"d-flex align-center",attrs:{"slot":"right"},slot:"right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.undoStack.length === 0},on:{"click":_vm.undo}},on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}])},[_c('span',[_vm._v("Undo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-n2 mr-n1",attrs:{"icon":"","disabled":_vm.redoStack.length === 0},on:{"click":_vm.redo}},on),[_c('v-icon',[_vm._v("mdi-redo")])],1)]}}])},[_c('span',[_vm._v("Redo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-n1",attrs:{"icon":""},on:{"click":_vm.toggleDrawer}},on),[_c('v-icon',[_vm._v("mdi-family-tree")])],1)]}}])},[_c('span',[_vm._v("Organize")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-n1",attrs:{"icon":""},on:{"click":_vm.exportToPDF}},on),[_c('v-icon',[_vm._v("mdi-file-export")])],1)]}}])},[_c('span',[_vm._v("Export to PDF")])])],1)]),_c('div',{staticClass:"d-flex splitter"},[_c('div',{class:{ 'dragging': _vm.draggingSidebar },attrs:{"id":"drawer"}},[_c('EOSAccountabilityChartSidePanel',{attrs:{"seats":[_vm.data.topLevelSeat]},on:{"drag-start":_vm.saveCurrentStateToUndo,"change":_vm.reorderSeats}})],1),_c('div',{attrs:{"id":"separator"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"278px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"position-absolute ml-0",staticStyle:{"top":"50%"},attrs:{"src":require("@/assets/images/pull-right.svg")}},on))]}}])},[_c('span',[_vm._v("Pull right to start dragging and dropping seats in your organizational chart")])])],1),_c('div',{staticClass:"d-flex flex-grow-1",class:{ 'dragging': _vm.draggingSidebar },attrs:{"id":"chart"}},[_c('EOSAccountabilityChart',{ref:"accoutabilityChart",attrs:{"chart-name":_vm.meta.name,"tags":_vm.data.tags,"initial-data":_vm.data.topLevelSeat},on:{"seats-updated":_vm.onSeatsUpdate,"update-tags":_vm.onTagsUpdate}})],1)]),(_vm.showImportExportModal)?_c('AppDialogJSONImportExport',{attrs:{"title":"Organisational Chart Data","allow-update":"","dataJSON":_vm.dataJSON,"show-loader":_vm.isUpdatingChartJSON},on:{"update-data":function($event){return _vm.updateChartJSON($event)},"close":_vm.closeImportExportModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }