<template>
  <v-menu
    v-if="tags.length > 0"
    offset-y
    nudge-bottom="2px"
    max-height="375px"
    max-width="225px"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-4 mt-3"
        v-bind="attrs"
        v-on="on"
        color="white"
        small
        absolute
        style="z-index: 1;"
      >
        Filter by Tags
      </v-btn>
    </template>

    <v-card flat>
      <v-card-title class="py-2 text-body-2">
        Showing {{ numberOfVisibleSeats }}
        <template v-if="numberOfVisibleSeats > 1">seats</template>
        <template v-else>seat</template>
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-0">
        <v-layout column>
          <v-flex
            v-for="tag in tags"
            :key="`legend-${tag.id}`"
            class="mt-3"
          >
            <v-chip
              :color="tag.bgColor || ''"
              label
              dark
              small
              class="eos-chart__seat__tag"
              @click="$emit('toggle-filter', tag)"
            >
              <v-expand-x-transition>
                <v-icon v-show="selectedTags.includes(tag.id)" :color="tag.textColor" left small>
                  mdi-check-bold
                </v-icon>
              </v-expand-x-transition>

              <span class="text-truncate  text-capitalize" :style="{ color: tag.textColor }">{{ tag.title }}</span>
            </v-chip>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'EOSAccountabilityChartFilterByTag',
  props: {
    tags: {
      type: Array,
      default: () => ([])
    },
    selectedTags: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      numberOfVisibleSeats: 0
    }
  },
  watch: {
    selectedTags: {
      immediate: true,
      handler: 'updateVisibleSeatCount'
    }
  },
  methods: {
    async updateVisibleSeatCount () {
      await this.$nextTick()
      const n = document.querySelectorAll('.eos-chart__card:not(.eos-chart__card--dimmed)') || []
      this.numberOfVisibleSeats = n.length
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-chart__seat__tag {
  width: 145px;
}
</style>
