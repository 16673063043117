export default class LayoutManager {
  constructor (data) {
    this.topLevelSeat = data.topLevelSeat
    this.parentSeatIdForSeatId = {}
    this.seatsById = {}
    this.seatsById[this.topLevelSeat.id] = this.topLevelSeat
    this.updateTime = new Date().getTime()
    this.blockWidth = 300
    this.blockWidthSpacing = 40
    this.blockHeight = 360
  }

  updateTopLevelSeat (seat) {
    this.topLevelSeat = seat
  }

  appendSubsToRows (parent, rows, rowNo) {
    if (typeof parent !== 'object') return rows
    if (typeof rowNo === 'undefined') rowNo = 0
    if (typeof rows[rowNo] === 'undefined') rows[rowNo] = []

    // loop through subs
    if (parent.isOpen) {
      parent.subs.forEach(subItem => {
        // Cache the parent Id
        this.parentSeatIdForSeatId[subItem.id] = parent.id
        // Cache the parent reference
        this.seatsById[subItem.id] = subItem
        // Append the seat to this row
        rows[rowNo].push(subItem)
        // Append this row sub items
        rows = this.appendSubsToRows(subItem, rows, rowNo + 1)
      })
    }

    return rows
  }

  updateLayout () {
    this.updateTime = new Date().getTime()
    this.getLayout()
  }

  getLayout () {
    // Do we use the layout cache?
    if (
      this.layoutCacheUpdateTime === this.updateTime &&
      this.layoutCache !== undefined
    ) {
      return this.layoutCache
    }

    const layout = { updateTime: this.updateTime, openSeats: [] }

    // Convert the entire nested structure to an array for each line
    let rows = []
    rows[0] = [this.topLevelSeat]
    rows = this.appendSubsToRows(this.topLevelSeat, rows, 1)

    // loop through all the items and do an inital plot
    //      []
    //    [][][]
    //  [][][]
    // loop from bottom to top, left to right
    const minX = 40
    const minY = 40
    const startX = -200
    const startY = 900
    let y = startY
    let minBoundsX = 0
    let minBoundsY = 0
    let maxBoundsX = 0
    let maxBoundsY = 0

    // Loop from bottom to top over each row
    for (let rowY = rows.length - 1; rowY >= 0; rowY--) {
      const row = rows[rowY]
      let x = startX
      let rowX = 0
      let prevSeat = null

      for (rowX; rowX < row.length; rowX++) {
        const seat = row[rowX]
        const parentNodeId = this.parentSeatIdForSeatId[seat.id]

        // remember if this seat is open and subs available
        if (seat.isOpen && seat.subs.length > 0) {
          layout.openSeats.unshift(seat.id)
        }

        // Get the parent seat
        const parentSeat = this.seatsById[parentNodeId]

        // Skip this item if the parent is closed
        if (parentSeat && !parentSeat.isOpen) continue

        // Determine if this is a new group
        let isNewGroup = rowX === 0
        if (
          prevSeat !== null &&
          this.parentSeatIdForSeatId[prevSeat.id] !== parentNodeId
        ) {
          isNewGroup = true
        }

        // Add spacing between horizontal groups
        if (isNewGroup) {
          x += this.blockWidthSpacing
        }

        // if this is a new group, check to see if we have bounds for
        // sub items and if so, calculate a new starting position for this row
        let subItemsLHS = x
        let subItemsRHS = x + this.blockWidth

        if (seat.isOpen && seat.subs.length > 0) {
          const firstSubItem = seat.subs[0]
          const lastSubItem = seat.subs[seat.subs.length - 1]

          // get lhs layout position
          subItemsLHS = layout[firstSubItem.id].x
          subItemsRHS = layout[lastSubItem.id].x + this.blockWidth

          const w = (subItemsRHS - subItemsLHS) / 2
          const xMidpointOfSub = subItemsLHS + w - this.blockWidth / 2
          // Move sub items over to right if needed
          if (xMidpointOfSub > x) x = xMidpointOfSub
          else if (xMidpointOfSub < x) {
            // we need to slide all subs to the right
            const xSlide = x - xMidpointOfSub
            this._slideSubRowOver(seat, layout, xSlide, rows, rowY)
          }
        }

        // Store the positon of this row
        const xy = { x: x, y: y }
        layout[seat.id] = xy
        prevSeat = seat

        // next xs
        x += this.blockWidth
      }

      // update bounding rectangle
      if (rowX === 0) minBoundsX = x
      else minBoundsX = Math.min(x, minBoundsX)
      if (rowY === 0) minBoundsY = y
      else minBoundsY = Math.min(y, minBoundsY)
      maxBoundsX = Math.max(x, maxBoundsX)
      maxBoundsY = Math.max(y, maxBoundsY)

      // slide the y position up
      y -= this.blockHeight
    }

    // Slide all elements to keep elements in left bounds
    let slideX = minX - minBoundsX
    const slideY = minY - minBoundsY
    // Lock to top element if it's too far left (less than center)
    const newTopLevPosX = layout[rows[0][0].id].x - slideX
    const minTopLevPosX = window.innerWidth / 2 - 380
    slideX = minTopLevPosX - newTopLevPosX
    if (minBoundsX + slideX < minX) slideX = minX - minBoundsX

    // Final step, update the position of every seat
    for (let rowY = rows.length - 1; rowY >= 0; rowY--) {
      const row = rows[rowY]
      for (let rowX = 0; rowX < row.length; rowX++) {
        const seat = row[rowX]
        layout[seat.id].x += slideX
        layout[seat.id].y += slideY
        // update the rol position
        seat.x = layout[seat.id].x
        seat.y = layout[seat.id].y
      }
    }

    this.layoutCacheUpdateTime = this.updateTime
    this.layoutCache = layout
    return layout
  }

  getSeatPos (seatId) {
    const seatPos = this.getLayout()
    let pos = seatPos[seatId]

    if (typeof pos === 'undefined') {
      pos = { x: 10, y: 10 }
      console.error('position for seat not found', seatId)
    }
    return pos
  }

  _slideSubRowOver (seat, layout, xSlide, rows, seatRowY) {
    const seatsToSlide = []

    if (seat.subs.length === 0) return

    // Get the first sub item
    const firstSub = seat.subs[0]
    const subsRow = rows[seatRowY + 1]
    const positionOfFirstSub = subsRow.indexOf(firstSub)

    if (firstSub === -1) {
      console.error('position of first sub in next row not found')
      return
    }

    // Add all items in this row to right of positionOfFirstSub
    for (let i = positionOfFirstSub; i < subsRow.length; i++) {
      seatsToSlide.push(subsRow[i])
    }

    // Move all seats
    let firstSubSeatDone = false
    for (let i = 0; i < seatsToSlide.length; i++) {
      const subSeat = seatsToSlide[i]
      layout[subSeat.id].x += xSlide

      // process all other sub nodes
      if (!firstSubSeatDone && subSeat.isOpen && subSeat.subs.length) {
        firstSubSeatDone = true
        this._slideSubRowOver(subSeat, layout, xSlide, rows, seatRowY + 1)
      }
    }
  }
}
