export default class Seat {
  constructor (data = {}) {
    this.id = data.id || new Date().valueOf()
    this.name = data.name || ''
    this.responsibilities = data.responsibilities || ''
    this.person = data.person
    this.isOpen = typeof data.isOpen !== 'undefined' ? data.isOpen : true
    this.subs = []
    this.x = 0
    this.y = 0
    this.isEditingResponsibilities = false
  }
}
