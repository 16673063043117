<template>
  <v-layout v-if="!isLoaded" column fill-height align-center justify-center>
    <AppErrorCard
      v-if="hasError"
      entity="organisational chart"
      :is-anonymous-user="loggedInUser.isAnonymous"
      :is-invited="!!$route.query.invited"
      :invitee-email="$route.query.email"
      :show-auth-loader="showAuthLoader"
      @reload="reloadChartDetails"
      @login-with-google="$emit('login-with-google')"
      @login-with-microsoft="$emit('login-with-microsoft')"
      @login-with-email-pass="$emit('login-with-email-pass', $event)"
      @signup-with-email-pass="$emit('signup-with-email-pass', $event)"
      @reset-password="$emit('reset-password', $event)"
      @update-password="$emit('update-password', $event)"
    />

    <AppLoader v-else />
  </v-layout>
  <div v-else>
    <AppPageToolbar
      entity="organisational chart"
      :entity-name="meta.name"
      :visibility="meta.visibility"
      :users="data.users"
      :domains="meta.permissions.domains"
      :logged-in-user="loggedInUser"
      @update-name="updateChartName"
      @update-privacy="updatePrivacy"
      @update-permissions="updatePermissions"
      @delete="deleteChart"
      @open-import-export-dialog="openImportExportModal"
      @set-message="setMessage"
    >
      <div class="d-flex align-center" slot="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :disabled="undoStack.length === 0" @click="undo">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </template>
          <span>Undo</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :disabled="redoStack.length === 0" class="ml-n2 mr-n1" @click="redo">
              <v-icon>mdi-redo</v-icon>
            </v-btn>
          </template>
          <span>Redo</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="mr-n1" @click="toggleDrawer">
              <v-icon>mdi-family-tree</v-icon>
            </v-btn>
          </template>
          <span>Organize</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="mr-n1" @click="exportToPDF">
              <v-icon>mdi-file-export</v-icon>
            </v-btn>
          </template>
          <span>Export to PDF</span>
        </v-tooltip>
      </div>
    </AppPageToolbar>

    <div class="d-flex splitter">
      <div id="drawer" :class="{ 'dragging': draggingSidebar }">
        <EOSAccountabilityChartSidePanel
          :seats="[data.topLevelSeat]"
          @drag-start="saveCurrentStateToUndo"
          @change="reorderSeats"
        />
      </div>
      <div id="separator">
        <v-tooltip top max-width="278px">
          <template v-slot:activator="{ on }">
            <v-img src="@/assets/images/pull-right.svg" v-on="on" class="position-absolute ml-0" style="top: 50%;" />
          </template>
          <span>Pull right to start dragging and dropping seats in your organizational chart</span>
        </v-tooltip>
      </div>
      <div id="chart" class="d-flex flex-grow-1" :class="{ 'dragging': draggingSidebar }">
        <EOSAccountabilityChart
          ref="accoutabilityChart"
          :chart-name="meta.name"
          :tags="data.tags"
          :initial-data="data.topLevelSeat"
          @seats-updated="onSeatsUpdate"
          @update-tags="onTagsUpdate"
        />
      </div>
    </div>

    <AppDialogJSONImportExport
      v-if="showImportExportModal"
      title="Organisational Chart Data"
      allow-update
      :dataJSON="dataJSON"
      :show-loader="isUpdatingChartJSON"
      @update-data="updateChartJSON($event)"
      @close="closeImportExportModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppPageToolbar from '@/components/shared/toolbars/AppPageToolbar'
import AppDialogJSONImportExport from '@/components/shared/dialogs/AppDialogJSONImportExport'
import AppErrorCard from '@/components/shared/errors/AppErrorCard'
import EOSAccountabilityChart from '@/components/eos/EOSAccountabilityChart/EOSAccountabilityChart'
import EOSAccountabilityChartSidePanel from '@/components/eos/EOSAccountabilityChart/EOSAccountabilityChartSidePanel'

import DataManager from '@/helpers/dataManager'
import FIREBASE_PERMISSION_ERROR_CODES from '@/enums/firebasePermissionErrorCodes'

const DEFAULT_OPEN_DRAWER_WIDTH = '300px'

export default {
  name: 'PageAccountabilityChart',
  components: {
    AppPageToolbar,
    AppDialogJSONImportExport,
    AppErrorCard,
    EOSAccountabilityChart,
    EOSAccountabilityChartSidePanel
  },
  metaInfo () {
    return {
      title: 'Organisational Chart'
    }
  },
  props: {
    showAuthLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      chartId: null,
      meta: {
        name: '',
        visibility: 'public',
        permissions: {
          domains: []
        }
      },
      data: {
        users: [],
        tags: [],
        topLevelSeat: {}
      },
      isLoaded: false,
      hasError: false,
      draggingSidebar: false,
      showImportExportModal: false,
      isUpdatingChartJSON: false,
      dataJSON: '',
      undoStack: [],
      redoStack: []
    }
  },
  computed: {
    ...mapGetters(['loggedInUser'])
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.loadChartDetailsById(to.params.chartId)
      }
    }
  },
  destroyed () {
    // destroy will prevent memory leaks
    this.dataManager.destroy()
  },
  methods: {
    ...mapActions(['setMessage']),

    async loadChartDetailsById (chartId) {
      this.chartId = null // will stop overwriting saves while we load
      this.dataManager.cancelSubscriptions()

      const handlerError = (error) => {
        this.hasError = true
        this.setMessage({ type: 'error', message: error.message })
        // remove from list if do not have access
        if (FIREBASE_PERMISSION_ERROR_CODES.includes(error.code)) {
          this.dataManager.updateUserReferenceToObject('charts', chartId)
        }
      }

      try {
        this.dataManager.syncObject('charts', chartId, async (error, chartObject) => {
          if (error) return handlerError(error)

          this.meta = { ...this.meta, ...chartObject.meta }
          this.data = { ...this.data, ...chartObject.data }

          this.chartId = chartId
          // register events if it's first-time loaded
          if (!this.isLoaded) {
            this.isLoaded = true
            await this.$nextTick()
            this.registerEvents()
          } else {
            this.$refs.accoutabilityChart.setData(this.data.topLevelSeat)
          }
          this.hasError = false
        })
      } catch (error) {
        handlerError(error)
      }
    },

    reloadChartDetails () {
      this.loadChartDetailsById(this.$route.params.chartId)
    },

    saveChart (saveVersion = false) {
      if (!this.isLoaded || this.chartId === null || !this.loggedInUser) return

      // get the data from the chart
      const accoutabilityChart = this.$refs.accoutabilityChart
      if (!accoutabilityChart) {
        return this.setMessage({
          type: 'error',
          message: `Error saving org. chart: Chart is ${typeof accoutabilityChart}`
        })
      }

      try {
        if (saveVersion) this.saveCurrentStateToUndo()
        const topLevelSeat = JSON.parse(JSON.stringify(accoutabilityChart.topLevelSeat)) // deep clone
        this.$set(this.data, 'topLevelSeat', topLevelSeat)

        return this.dataManager.saveObject('charts', this.chartId, {
          meta: this.meta,
          data: {
            tags: this.data.tags,
            users: this.data.users,
            topLevelSeat
          }
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error saving org. chart: ${error.message}` })
      }
    },

    onSeatsUpdate (saveStatus) {
      if (this.isLoaded) this.saveChart(saveStatus)
    },

    updateChartName (newName) {
      try {
        this.$set(this.meta, 'name', newName)
        this.saveChart()
        this.dataManager.updateObjectNameInList('charts', this.chartId, this.meta.name)
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },

    updatePrivacy (visibility) {
      this.$set(this.meta, 'visibility', visibility)
      this.saveChart()
      this.$analytics('org_chart_changed_privacy', { type: visibility })
    },

    async updatePermissions ({ domains, usersMeta, users }) {
      await this.$nextTick() // let the chart get ready
      this.$set(this.meta, 'permissions', { domains, ...usersMeta })
      this.$set(this.data, 'users', users)
      this.saveChart()
    },

    async deleteChart () {
      try {
        this.isLoaded = false
        this.dataManager.cancelSubscriptions()
        await this.dataManager.deleteObject('charts', this.chartId)
        this.$router.push({ name: 'home' })
      } catch (error) {
        this.isLoaded = true
        this.setMessage({ type: 'error', message: `Error deleting scorecard: ${error.message}` })
      }
    },

    openImportExportModal () {
      this.dataJSON = JSON.stringify(this.$refs.accoutabilityChart.topLevelSeat, null, 4)
      this.showImportExportModal = true
    },

    async updateChartJSON (json) {
      try {
        this.isUpdatingChartJSON = true
        this.$refs.accoutabilityChart.setData(JSON.parse(json))
        await this.saveChart()
        this.closeImportExportModal()
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating data: ${error.message}` })
      } finally {
        this.isUpdatingChartJSON = false
      }
    },

    closeImportExportModal () {
      this.showImportExportModal = false
    },

    reorderSeats (seats) {
      const topLevelSeat = JSON.stringify(seats[0])
      this.updateChartJSON(topLevelSeat)
      this.$analytics('org_chart_reordered_seat', { id: this.chartId })
    },

    toggleDrawer () {
      const drawer = document.getElementById('drawer')
      drawer.style.width = drawer.style.width >= DEFAULT_OPEN_DRAWER_WIDTH ? '0%' : '300px'
      this.$analytics('org_chart_toggled_drawer', { id: this.chartId })
    },

    registerEvents () {
      let md // remember mouse down info
      const element = document.getElementById('separator')
      const drawer = document.getElementById('drawer')
      const chart = document.getElementById('chart')

      const onMouseDown = (e) => {
        md = {
          e,
          offsetLeft: element.offsetLeft,
          offsetTop: element.offsetTop,
          drawerWidth: drawer.offsetWidth,
          chartWidth: chart.offsetWidth
        }

        this.draggingSidebar = true

        document.onmousemove = onMouseMove
        document.ontouchmove = onMouseMove
        document.onmouseup = () => {
          this.draggingSidebar = false
          document.onmousemove = document.onmouseup = null
        }
        document.ontouchend = () => {
          this.draggingSidebar = false
          document.ontouchmove = document.ontouchmove = null
        }
      }

      const onMouseMove = (e) => {
        const delta = {
          x: e.targetTouches ? (e.targetTouches[0].clientX - md.e.targetTouches[0].clientX) : (e.clientX - md.e.clientX),
          y: e.targetTouches ? (e.targetTouches[0].clientX - md.e.targetTouches[0].clientX) : (e.clientY - md.e.clientY)
        }

        // Prevent negative-sized elements
        delta.x = Math.min(Math.max(delta.x, -md.drawerWidth), md.chartWidth)

        drawer.style.width = (md.drawerWidth + delta.x) + 'px'
        element.style.left = md.offsetLeft + delta.x + 'px'
        chart.style.width = (md.chartWidth - delta.x) + 'px'
      }

      element.onmousedown = onMouseDown
      element.ontouchstart = onMouseDown
    },

    saveCurrentStateToUndo () {
      this.undoStack.push(JSON.stringify(this.data.topLevelSeat))
    },

    undo () {
      this.redoStack.push(JSON.stringify(this.data.topLevelSeat))
      this.updateChartJSON(this.undoStack.pop())
      this.$analytics('org_used_undo', { id: this.chartId })
    },

    redo () {
      this.saveCurrentStateToUndo()
      this.updateChartJSON(this.redoStack.pop())
      this.$analytics('org_used_redo', { id: this.chartId })
    },

    onTagsUpdate (tags = []) {
      try {
        this.dataManager.updateObject('charts', this.chartId, {
          'data.tags': tags
        })
        this.$analytics('org_updated_tags', { id: this.chartId })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error saving tags: ${error.message}` })
      }
    },

    exportToPDF () {
      this.$refs.accoutabilityChart.exportToPDF()
      this.$analytics('org_chart_exported_pdf', { id: this.chartId })
    }
  }
}
</script>

<style lang="scss" scoped>
.splitter {
  width: 100%;
  height: calc(100vh - 96px);
}

.dragging {
  pointer-events: none;
}

#separator {
  cursor: col-resize;
  background-color: #eee;
  // background-image: url("~@/assets/images/pull-right.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 10px;
  height: 100%;
  z-index: 2;

  /* Prevent the browser's built-in drag from interfering */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#drawer {
  width: 0%;
  height: 100%;
  min-width: 15px;
  z-index: 2;
}

#chart {
  width: 100%;
  height: 100%;
  min-width: 10px;
}
</style>
