<template>
  <draggable
    v-bind="dragOptions"
    tag="div"
    :group="{ name: 'section' }"
    :list="seats"
    @start="$emit('drag-start')"
    @change="emitChange"
  >
    <ul :key="seat.id" v-for="(seat, index) in formattedSeats" :class="listClass">
      <div class="d-flex pa-2 eos-chart__treeview__section">
        <v-btn v-if="seat.subs.length > 0" icon small class="mt-n1" @click="toggleVisibility(index, !seat.isOpen)">
          <v-icon small>{{ seat.isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
        <li
          class="d-inline-flex text-no-wrap eos-chart__treeview__node"
          :class="{
            'ml-1': seat.subs.length === 0,
            'disabled': disabled
          }"
        >
          <span>{{ seat.name || 'Seat Title' }}</span>
          <span class="ml-1">-</span>
          <AppAvatar
            :src="seat.person && seat.person.avatarURL ? seat.person.avatarURL : ''"
            :name="seat.person && seat.person.fullName ? seat.person.fullName : '?'"
            size="24px"
            name-class="eos-chart__treeview__person-name"
          />
          <span class="ml-1">{{ seat.person ? seat.person.fullName : '' }}</span>
        </li>
      </div>

      <v-expand-transition>
        <EOSAccountabilityChartTreeView
          v-if="seat.subs.length > 0"
          v-show="seat.isOpen"
          :seats="seat.subs"
          @drag-start="$emit('drag-start')"
          @change="emitChange"
        />
      </v-expand-transition>
    </ul>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'EOSAccountabilityChartTreeView',
  components: { draggable },
  props: {
    seats: {
      type: Array,
      default: () => ([])
    },
    listClass: {
      type: [String, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dragOptions () {
      return {
        disabled: this.disabled
      }
    },
    formattedSeats () {
      return this.seats.map(seat => ({
        ...seat,
        subs: seat.subs || [],
        isOpen: !!seat.isOpen
      }))
    }
  },
  methods: {
    emitChange () {
      this.$emit('change', this.formattedSeats)
    },
    toggleVisibility (index, status) {
      this.$set(this.seats[index], 'isOpen', status)
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-chart__treeview__section {
  &:hover {
    background-color: #EAEAEA;
  }
}

.eos-chart__treeview__node:not(.disabled) {
  cursor: move;
}

::v-deep .eos-chart__treeview__person-name {
  font-size: 10px;
}
</style>
