<template>
  <v-navigation-drawer
    permanent
    width="100%"
    height="100%"
    class="drawer"
  >
    <p class="pt-3 px-5 mb-2">Drag and Drop</p>
    <EOSAccountabilityChartTreeView
      disabled
      list-class="pl-0"
      :seats="seats"
      @drag-start="$emit('drag-start')"
      @change="emitChange"
    />
  </v-navigation-drawer>
</template>

<script>
import EOSAccountabilityChartTreeView from '@/components/eos/EOSAccountabilityChart/EOSAccountabilityChartTreeView'

export default {
  name: 'EOSAccountabilityChartSidePanel',
  components: { EOSAccountabilityChartTreeView },
  props: {
    seats: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    emitChange () {
      this.$emit('change', this.seats)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
  width: 12px;
}

::v-deep .v-navigation-drawer__content::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::v-deep .v-navigation-drawer__content:not(:hover)::-webkit-scrollbar-thumb {
  background-color: transparent;
  -webkit-box-shadow: none;
}

::v-deep .v-navigation-drawer__content::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::v-deep .v-navigation-drawer__content::-webkit-scrollbar-corner {
  background-color: transparent;
}
</style>
