<template>
  <v-menu
    open-on-hover
    offset-y
    :top="top"
    :bottom="!top"
    nudge-bottom="1px"
    max-width="275px"
  >
    <template v-slot:activator="{ on }">
      <v-list-item-title class="cursor-pointer" :class="textClass" v-on="on">
        <template v-if="text">{{ text }}</template>
        <template v-else>Sync with Teamwork <AppTeamworkBranding info /></template>
      </v-list-item-title>
    </template>

    <v-list class="pa-0 text-body-2" color="white" dense>
      <v-list-item
        v-for="site in teamworkSites"
        :key="site.twAPIEndPoint"
        @click="selectTWSiteToSync(site)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ site.twSiteName }}</v-list-item-title>
          <v-list-item-subtitle>{{ site.twURL }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-4 primary--text text-decoration-none cursor-pointer" @click="$emit('initialize-tw-oauth-process')">
        <v-icon size="20px" color="primary" class="mr-0">mdi-plus</v-icon>
        Sync with a new Teamwork site
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import AppTeamworkBranding from '@/components/shared/misc/AppTeamworkBranding'

export default {
  name: 'AppTeamworkSyncSites',
  components: { AppTeamworkBranding },
  props: {
    teamworkSites: {
      type: Array,
      default: () => ([])
    },
    text: {
      type: String,
      default: ''
    },
    textClass: {
      type: [String, Array],
      default: ''
    },
    top: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectTWSiteToSync (site) {
      this.$emit('save-sync-options', {
        accessToken: site.twAccessToken,
        apiEndPoint: site.twAPIEndPoint,
        domain: site.twURL,
        siteName: site.twSiteName || ''
      })
    }
  }
}
</script>
