<template>
  <v-list-item v-if="twSyncOptions.accessToken" @click="showSyncOptions">
    <v-list-item-title>
      <template v-if="!twSyncOptions.projectId || !twSyncOptions.tasklistId">Select</template>
      <template v-else>Update</template>
      Default Sync Options
    </v-list-item-title>
  </v-list-item>

  <v-list-item v-else-if="syncedTWSites.length > 0">
    <AppTeamworkSyncSites
      :teamwork-sites="syncedTWSites"
      @initialize-tw-oauth-process="syncWithNewTWSite"
      @save-sync-options="$emit('save-sync-options', $event)"
    />
  </v-list-item>

  <v-list-item v-else>
    <v-list-item-title @click="syncWithNewTWSite">
      Sync with Teamwork <AppTeamworkBranding info />
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import AppTeamworkSyncSites from '@/components/shared/sync/AppTeamworkSyncSites'
import AppTeamworkBranding from '@/components/shared/misc/AppTeamworkBranding'

export default {
  name: 'AppSyncOptions',
  components: { AppTeamworkSyncSites, AppTeamworkBranding },
  props: {
    twSyncOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['syncedTWSites'])
  },
  methods: {
    showSyncOptions () {
      this.$emit('show-sync-options')
    },
    syncWithNewTWSite () {
      this.$emit('initialize-tw-oauth-process')
    }
  }
}
</script>
