<template>
  <div>
    <div
      :ref="`seatCard${seat.id}`"
      class="mt-0 mr-10 mb-10 ml-0 cursor-pointer position-absolute eos-chart__card"
      :class="{
        'eos-chart__card--closed': !isCascadeOpen,
        'eos-chart__card--dimmed': dimSeat
      }"
      :style="{
        transform: transformStyle,
        zIndex: 999 - yNo
      }"
    >
      <div class="pa-0 position-relative eos-chart__card__content">
        <span class="d-inline-block position-absolute eos-chart__card__btn-delete">
          <v-tooltip v-if="parentSeat.id && !hasSubSeats && !isEditing" bottom>
            <template v-slot:activator="{ on }">
              <a
                v-on="on"
                href="javascript:;"
                class="ma-0 d-inline-block text-center"
                @click="deleteSeat"
              >
                <img
                  src="@/assets/accoutability-chart/delete.png"
                  width="16"
                  height="16"
                />
              </a>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </span>

        <input
          v-show="isEditing"
          v-model="seat.name"
          ref="seatTitle"
          placeholder="Seat title"
          class="ma-0 text-start font-weight-bold eos-chart__card__title"
          @blur="isEditing = false"
          @change="saveName"
        />
        <div
          v-show="!isEditing"
          class="ma-0 text-start font-weight-bold eos-chart__card__title"
          @click="isEditing = true"
        >
          {{ seat.name || 'Seat Title' }}
        </div>

        <div class="ma-0 pa-0 position-relative eos-chart__card__avatar__wrapper">
          <span
            class="d-block position-absolute text-center font-weight-bold eos-chart__card__avatar"
            :style="{ backgroundColor: personInitialsColor, color: primaryTag.textColor || '#000000' }"
          >
            {{ personInitials }}
          </span>

          <input
            v-model="seat.person.fullName"
            placeholder="Who is in this seat?"
            class="ma-0 text-start"
            @change="emitUpdate"
          />
        </div>

        <div class="ma-0 text-start cursor-pointer eos-chart__card__responsibilities">
          <textarea
            v-if="seat.isEditingResponsibilities"
            ref="responsibilitiesTextarea"
            v-model="seat.responsibilities"
            placeholder="Roles"
            @blur="seat.isEditingResponsibilities = false"
            @change="saveResponsibilities"
          />
          <div
            v-else
            class="eos-chart__card__responsibilities__list"
            @click="editResponsibilities"
          >
            <H5>Roles</H5>
            <ul v-if="seat.responsibilities.length === 0">
              <li>No role set</li>
            </ul>
            <transition name="dropdown" v-else>
              <ul v-if="!isExporting">
                <li v-for="(item, index) in firstFiveResponsibilities" :key="`${item}-${index}`">
                  {{ item }}
                </li>
              </ul>
              <ul v-else>
                <li v-for="(item, index) in firstEightResponsibilities" :key="`${item}-${index}`">
                  {{ item }}
                </li>
              </ul>
            </transition>
          </div>
        </div>

        <div  class="d-flex align-center px-1 eos-chart__card__tags">
          <template v-if="hasTags">
            <v-chip
              v-for="tag in previewSeatTags"
              :key="`tag-overview-${tag.id}`"
              class="ma-1"
              :color="tag.bgColor || ''"
              small
              @click="openTagList"
            >
              <span class="text-truncate" :style="{ color: tag.textColor }">{{ tag.title }}</span>
            </v-chip>
            <div
              v-if="(seatTags.length - 2) > 0"
              class="ml-2 mr-1 d-flex text-no-wrap text-caption text--disabled"
              @click="openTagList"
            >
              +{{ seatTags.length - 2 }} more
            </div>
          </template>
          <a
            v-else
            href="javascript:;"
            class="text-center text-uppercase text-decoration-none eos-chart__card__add-tag"
            @click="addTag"
          >Add Tag</a>
        </div>

        <div class="d-block position-relative eos-chart__card__footer">
          <a
            href="javascript:;"
            class="d-block position-absolute text-center text-uppercase text-decoration-none eos-chart__card__add-seat"
            @click="addSeat"
          >Add seat</a>

          <v-tooltip v-if="hasSubSeats" bottom>
            <template v-slot:activator="{ on }">
              <a
                v-on="on"
                href="javascript:;"
                class="d-block position-absolute text-center eos-chart__card__show-more"
                :class="{
                  'eos-chart__card--is-opened': seat.isOpen,
                  'eos-chart__card--is-closed': !seat.isOpen
                }"
                @click="toggleOpenStatus"
              >
                <img
                  :src="require(`@/assets/accoutability-chart/chevron-${(seat.isOpen ? 'down' :'up')}.png`)"
                  width="16"
                  height="16"
                />
              </a>
            </template>
            <span>{{ seat.isOpen ? 'Collapse' : 'Expand' }}</span>
          </v-tooltip>

          <span class="d-inline-block position-absolute eos-chart__swap-icons">
            <AccountabilityChartSeatTags
              ref="seatTagsList"
              :seat-id="seat.id"
              :tags="tags"
              :seat-tags="seatTags"
              :primary-tag="primaryTag"
              @update-tags="updateTags"
            />

            <v-tooltip v-if="!isRootNode" bottom>
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  href="javascript:;"
                  @click="moveSeatToUp"
                >
                  <img src="@/assets/accoutability-chart/arrow-up.png" width="16" height="16" />
                </a>
              </template>
              <span>Move Up</span>
            </v-tooltip>

            <v-tooltip v-if="!isRootNode && hasSubSeats" bottom>
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  href="javascript:;"
                  @click="moveSeatToDown"
                >
                  <img src="@/assets/accoutability-chart/arrow-down.png" width="16" height="16" />
                </a>
              </template>
              <span>Move Down</span>
            </v-tooltip>

            <template v-if="hasSiblings">
              <v-tooltip v-if="canMoveLeft()" bottom>
                <template v-slot:activator="{ on }">
                  <a
                    v-on="on"
                    href="javascript:;"
                    @click="moveSeatToLeft"
                  >
                    <img src="@/assets/accoutability-chart/arrow-left.png" width="16" height="16" />
                  </a>
                </template>
                <span>Move Left</span>
              </v-tooltip>
              <v-tooltip v-if="canMoveRight()" bottom>
                <template v-slot:activator="{ on }">
                  <a
                    v-on="on"
                    href="javascript:;"
                    @click="moveSeatToRight"
                  >
                    <img src="@/assets/accoutability-chart/arrow-right.png" width="16" height="16" />
                  </a>
                </template>
                <span>Move Right</span>
              </v-tooltip>
            </template>
          </span>
        </div>
      </div>
    </div>

    <template v-if="hasSubSeats">
      <div v-for="subSeat in seat.subs" :key="subSeat.id">
        <EOSAccountabilityChartSeatCard
          :ref="`vueSeatCard${subSeat.id}`"
          v-show="isCascadeOpen"
          :parent-seat="seat"
          :seat="subSeat"
          :layout="layout"
          :tags="tags"
          :filter-by-tags="filterByTags"
          :is-parent-cascade-open="isCascadeOpen"
          :parent-transform-style="transformStyle"
          :y-no="yNo + 1"
          :is-exporting="isExporting"
          @add-seat="$emit('add-seat', $event)"
          @add-seat-to-parent="addSeatToParent"
          @add-seat-to-grand-parent="addSeatToGrandParent"
          @seats-updated="$emit('seats-updated', $event)"
          @update-tags="updateTags"
        />
      </div>
    </template>
  </div>
</template>

<script>
import AccountabilityChartSeatTags from './EOSAccountabilityChartSeatTags'

export default {
  name: 'EOSAccountabilityChartSeatCard',
  components: {
    AccountabilityChartSeatTags
  },
  props: {
    parentSeat: {
      type: Object,
      default: () => ({})
    },
    seat: {
      type: Object,
      default: () => ({})
    },
    layout: {
      type: Object,
      default: () => ({})
    },
    yNo: {
      type: Number,
      default: 0
    },
    isParentCascadeOpen: {
      type: Boolean,
      default: true
    },
    parentTransformStyle: {
      type: String,
      default: ''
    },
    tags: {
      type: Array,
      default: () => ([])
    },
    filterByTags: {
      type: Array,
      default: () => ([])
    },
    isExporting: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isEditing: false
    }
  },
  watch: {
    async isEditing (editing) {
      await this.$nextTick()
      this.$refs.seatTitle.focus()
    }
  },
  computed: {
    personInitials () {
      const name = this.seat.person.fullName
      return name ? this.$options.filters.shortName(name) : '?'
    },
    personInitialsColor () {
      if (this.primaryTag.bgColor) return this.primaryTag.bgColor

      const fullName = this.seat.person.fullName
      const colors = [
        '#FF9AA2',
        '#FFB7B2',
        '#FFDAC1',
        '#E2F0CB',
        '#B5EAD7',
        '#C7CEEA'
      ]
      const n = this.seat.id + this.seat.person.id + fullName.length
      return colors[n % colors.length]
    },
    personResponsibilitiesSplit () {
      return this.seat.responsibilities.split('\n')
    },
    firstFiveResponsibilities () {
      if (this.personResponsibilitiesSplit.length <= 5) {
        return this.personResponsibilitiesSplit
      }
      const n = this.personResponsibilitiesSplit.slice().splice(0, 4)
      n.push('...')
      return n
    },
    firstEightResponsibilities () {
      if (this.personResponsibilitiesSplit.length <= 8) {
        return this.personResponsibilitiesSplit
      }
      const n = this.personResponsibilitiesSplit.slice().splice(0, 7)
      n.push('...')
      return n
    },
    siblings () {
      return this.parentSeat?.subs || []
    },
    hasSiblings () {
      return this.siblings.length > 1
    },
    hasSubSeats () {
      return this.seat.subs && this.seat.subs.length > 0
    },
    transformStyle () {
      if (!this.isParentCascadeOpen && this.parentSeat) {
        return this.parentTransformStyle
      }
      return (`translate3d(${this.seat.x}px, ${this.seat.y}px, 0px)`)
    },
    isCascadeOpen () {
      return this.isParentCascadeOpen && this.seat.isOpen
    },
    isRootNode () {
      return !this.parentSeat.subs
    },
    seatTags () {
      const tags = this.otherSelectedTags.slice()
      if (this.primaryTag.id) tags.unshift(this.primaryTag)
      return tags
    },
    seatTagIds () {
      return this.seatTags.map(tag => tag.id)
    },
    hasTags () {
      return this.seatTags.length > 0
    },
    primaryTag () {
      return this.tags.find(tag => tag.primaryForSeatIds.includes(this.seat.id)) || {}
    },
    otherSelectedTags () {
      return this.tags.filter(tag => tag.selectedSeatIds.includes(this.seat.id) && tag.id !== this.primaryTag.id)
    },
    previewSeatTags () {
      return this.seatTags.slice(0, 2)
    },
    dimSeat () {
      return this.filterByTags.length > 0 && !this.seatTagIds.some(id => this.filterByTags.includes(id))
    }
  },
  methods: {
    saveName () {
      this.isEditing = false
      this.emitUpdate()
    },
    async editResponsibilities () {
      this.seat.isEditingResponsibilities = true
      await this.$nextTick()
      this.$refs.responsibilitiesTextarea.focus()
    },
    saveResponsibilities () {
      this.seat.isEditingResponsibilities = false
      this.emitUpdate()
    },
    addSeat () {
      this.$emit('add-seat', this.seat)
    },
    deleteSeat () {
      if (this.seat.subs.length > 0) return
      const index = this.parentSeat.subs.indexOf(this.seat)
      if (index < 0) return
      this.parentSeat.subs.splice(index, 1)
      this.emitUpdate()
    },
    toggleOpenStatus () {
      this.seat.isOpen = !this.seat.isOpen
      this.emitUpdate(false)
    },
    canMoveLeft () {
      return this.hasSiblings && this.siblings.indexOf(this.seat) > 0
    },
    canMoveRight () {
      return this.hasSiblings && this.siblings.indexOf(this.seat) < this.siblings.length - 1
    },
    moveSeatToLeft () {
      if (!this.canMoveLeft()) return

      const subs = this.parentSeat.subs
      const pos = subs.indexOf(this.seat)
      if (pos < 1) return

      const seatToLeft = subs[pos - 1]
      subs[pos - 1] = this.seat
      subs[pos] = seatToLeft

      this.emitUpdate()
    },
    moveSeatToRight () {
      if (!this.canMoveRight()) return

      const subs = this.parentSeat.subs
      const pos = subs.indexOf(this.seat)
      if (pos < 0 || pos > subs.length - 1) return

      const seatToRight = subs[pos + 1]
      subs[pos + 1] = this.seat
      subs[pos] = seatToRight

      this.emitUpdate()
    },
    moveSeatToUp () {
      const seat = this.seat
      const index = this.parentSeat.subs.indexOf(this.seat)

      this.parentSeat.subs.splice(index, 1)
      this.$emit('add-seat-to-grand-parent', seat)
    },
    moveSeatToDown () {
      const seat = this.seat
      const index = this.parentSeat.subs.indexOf(this.seat)

      this.parentSeat.subs.splice(index, 1)

      if (seat.subs.length > 1) {
        seat.subs.forEach(subSeat => this.$emit('add-seat-to-parent', subSeat))
        this.$emit('add-seat-to-parent', { ...seat, subs: [] })
      } else {
        const subSeat = seat.subs[0]
        subSeat.subs = [{ ...seat, subs: subSeat.subs || [] }]
        this.$emit('add-seat-to-parent', subSeat)
      }
      this.emitUpdate()
    },
    addSeatToGrandParent (seat) {
      // if parent is root node then make clicked seat as new root
      this.isRootNode ? this.$emit('update-top-level-seat', seat) : this.parentSeat.subs.push(seat)
      this.$emit('seats-updated')
    },
    addSeatToParent (seat) {
      this.seat.subs.push(seat)
    },
    emitUpdate (saveStatus = true) {
      this.$emit('seats-updated', saveStatus)
    },
    updateTags (tags) {
      this.$emit('update-tags', tags)
    },
    openTagList () {
      this.$refs.seatTagsList.showMenu = true
    },
    addTag () {
      this.tags.length > 0 ? this.openTagList() : this.$refs.seatTagsList.openDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-chart__card {
  width: 280px;
  z-index: 15;
  transition: 0.8s ease-in-out;
  opacity: 1;
  box-sizing: content-box;

  span.eos-chart__card__btn-delete {
    top: 7px;
    right: 10px;

    a {
      width: 20px;
      height: 20px;
      line-height: 16px;
      font-size: 12px;
      color: #aaa;
      opacity: 0.3;

      &:hover {
        color: rgb(64, 103, 233);
        text-decoration: none;
        opacity: 1;
      }
    }
  }
}

.eos-chart__card__content {
  width: 280px;
  border: 2px solid #ddd;
  border-top: 1px solid #aa2222;
  border-radius: 0px;
  box-shadow: 0 1px 4px rgba(50, 51, 52, 0.2);
  background: #fff;
  box-sizing: content-box;
}

.eos-chart__card--closed {
  .eos-chart__card__content {
    box-shadow: none;
  }
}

.eos-chart__card--dimmed {
  opacity: 0.3;
  pointer-events: none;
}

.eos-chart__card__title {
  box-sizing: content-box;
  width: 245px;
  font-size: 16px;
  line-height: 16px;
  height: 22px;
  padding: 15px 15px 15px 20px;
  border: none;
}

.eos-chart__card__avatar__wrapper {
  box-sizing: content-box;

  .eos-chart__card__avatar {
    left: 15px;
    top: -5px;
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    padding: 5px;
    border-radius: 50%;
    background: #eee;
    line-height: 40px;
    box-sizing: content-box;
  }

  input {
    font-size: 15px;
    line-height: 13px;
    padding: 10px 20px 15px 80px;
    width: 178px;
    border: none;
    box-sizing: content-box;
  }
}

.eos-chart__card__responsibilities {
  margin: 15px 0 0 0;
  padding: 10px;
  height: 130px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  box-sizing: content-box;

  h5 {
    padding: 10px 0 5px 5px;
    color: #888;
    font-size: 13px;
    line-height: 14px;
    font-weight: normal;
    box-sizing: content-box;
  }

  ul {
    padding: 0 20px 10px 25px;
    margin: 0;
    text-align: left;
    color: #333;
    font-size: 13px;
    max-height: 100px;
    overflow: hidden;

    li {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      list-style-type: square;
      line-height: 20px;
    }
  }

  textarea {
    padding: 10px 10px 10px 10px;
    width: 240px;
    height: 120px;
    margin: 0;
    text-align: left;
    color: #333;
    font-size: 13px;
    border: none;
    white-space: nowrap;
    box-sizing: content-box;
  }
}

.eos-chart__card__responsibilities__list {
  min-height: 140px;
  overflow: hidden;
}

.eos-chart__card__footer {
  margin: 5px 0 0 0;
  font-size: 12px;
  height: 30px;
}

a.eos-chart__card__add-seat,
a.eos-chart__card__add-tag {
  left: 5px;
  top: 3px;
  width: 80px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  color: #1f7dee;
  color: #777;

  &:hover {
    background: #f7f7f7;
    color: #2f8dff;
  }
}

a.eos-chart__card__show-more {
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
  width: 20px;
  height: 20px;
  color: #1f7dee;
  font-size: 12px;
  line-height: 20px;

  img {
    margin-top: 2px;
  }

  &:hover {
    background: #f7f7f7;
    color: #2f8dff;
    border-radius: 50%;
  }
}

span.eos-chart__swap-icons {
  top: 5px;
  right: 10px;

  a {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #aaa;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }
}

.eos-chart__card__tags {
  min-height: 33px;
  border-bottom: 1px solid #eee;
}

/* transition */
.dropdown-enter {
  transform: scaleY(0.7);
  opacity: 0;
}
.dropdown-leave-to {
  transform: scaleY(0.7);
  opacity: 0;
}
.dropdown-enter-to {
  opacity: 1;
  transform: scaleY(1);
}
.dropdown-leave {
  opacity: 1;
  transform: scaleY(1);
}
.dropdown-enter-active {
  transition: all 0.3s ease-out;
  transform-origin: top center;
}
.dropdown-leave-active {
  transition: all 0.3s ease-out;
  transform-origin: top center;
}
</style>
