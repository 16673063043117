<template>
  <div
    class="pa-0 d-block position-relative eos-chart"
    @mousedown="onMouseDown"
    @mouseleave="onMouseUp"
    @mouseup="onMouseUp"
    @mousemove="onMouseMove"
  >
    <AccountabilityChartFilterByTag
      :tags="tags"
      :selected-tags="filterByTags"
      @toggle-filter="toggleFilter"
    />

    <div
      ref="accountabilityChartWrapper"
      class="pa-0 d-block position-relative eos-chart__wrapper"
    >
      <div ref="accountabilityChartPDFWrapper" :class="{ 'eos-chart__pdf': isExportingToPDF }">
        <svg class="d-block eos-chart__svg" v-html="chartLinksSVG" />
        <div :style="{ transform: zoomStyle }">
          <AccountabilityChartSeatCard
            :ref="`vueSeatCard${topLevelSeat.id}`"
            :seat="topLevelSeat"
            :layout="layoutManager"
            :tags="tags"
            :filter-by-tags="filterByTags"
            :is-exporting="isExportingToPDF"
            @add-seat="addSeat"
            @update-top-level-seat="updateTopLevelSeat"
            @seats-updated="emitEventAndUpdateSVG"
            @update-tags="$emit('update-tags', $event)"
          />
        </div>
      </div>
    </div>

    <div class="position-absolute eos-chart__zoom-controls">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <a
            v-on="on"
            class="eos-chart__zoom-controls__zoom-in"
            href="javascript:;"
            @click="zoomIn"
          ></a>
        </template>
        <span>Zoom in</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <a
            v-on="on"
            class="eos-chart__zoom-controls__zoom-out"
            href="javascript:;"
            @click="zoomOut"
          ></a>
        </template>
        <span>Zoom out</span>
      </v-tooltip>
      <v-tooltip v-if="zoomScale !== 1" bottom>
        <template v-slot:activator="{ on }">
          <a
            v-on="on"
            class="font-weight-bold eos-chart__zoom-controls__reset-zoom"
            href="javascript:;"
            @click="resetZoom"
          >100%</a>
        </template>
        <span>Reset zoom</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <a
            v-on="on"
            class="eos-chart__zoom-controls__fit-content"
            href="javascript:;"
            @click="zoomToExtent"
          ></a>
        </template>
        <span>Show all</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="elevation-3" icon v-on="on" @click="expandAllSeats">
            <v-icon size="20px" color="#424242">mdi-arrow-expand-all</v-icon>
          </v-btn>
        </template>
        <span>Expand all</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image'
import { jsPDF } from 'jspdf'

import AccountabilityChartSeatCard from './EOSAccountabilityChartSeatCard'
import AccountabilityChartFilterByTag from './EOSAccountabilityChartFilterByTag'

import LayoutManager from '@/helpers/accountabilityChartLayoutManager'
import Seat from '@/models/seat'
import Person from '@/models/person'

export default {
  name: 'EOSAccountabilityChart',
  components: {
    AccountabilityChartSeatCard,
    AccountabilityChartFilterByTag
  },
  props: {
    initialData: {
      type: Object,
      default: () => ({})
    },
    tags: {
      type: Array,
      default: () => ([])
    },
    chartName: {
      type: String,
      default: 'org-chart'
    }
  },
  data () {
    return {
      layoutManager: null,
      topLevelSeat: null,
      chartLinksSVG: '',
      zoomScale: 1,
      zoomStep: 0.1,
      isPanning: false,
      panningOrigin: {
        x: 0,
        y: 0
      },
      filterByTags: [],
      isExportingToPDF: false
    }
  },
  computed: {
    zoomStyle () {
      return `scale(${this.zoomScale})`
    }
  },
  created () {
    this.setData(this.initialData)
    window.addEventListener('resize', this.onWindowResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    onMouseDown (e) {
      this.panningOrigin = { x: e.clientX, y: e.clientY }
      this.isPanning = true
    },
    onMouseUp () {
      this.isPanning = false
    },
    onMouseMove (e) {
      if (!this.isPanning) return

      const delta = {
        x: e.clientX - this.panningOrigin.x,
        y: e.clientY - this.panningOrigin.y
      }
      this.panningOrigin = { x: e.clientX, y: e.clientY }

      const elm = this.$refs.accountabilityChartWrapper
      elm.scrollLeft -= delta.x
      elm.scrollTop -= delta.y
    },
    zoomIn () {
      this.zoomScale += this.zoomStep
      this.onSeatsUpdate()
      this.$analytics('org_used_zoom_in')
    },
    zoomOut () {
      this.zoomScale -= this.zoomStep
      this.onSeatsUpdate()
      this.$analytics('org_used_zoom_out')
    },
    resetZoom () {
      this.zoomScale = 1
      this.onSeatsUpdate()
      this.$analytics('org_used_show_all')
    },
    zoomToExtent () {
      const layout = this.layoutManager.getLayout()

      let maxX = 0
      let maxY = 0

      layout.openSeats.forEach(seatId => {
        const seat = this.layoutManager.seatsById[seatId]
        if (!seat || seat.subs.length === 0) return

        maxX = Math.max(maxX, seat.x)
        maxY = Math.max(maxY, seat.y)

        if (!seat.isOpen) return
        seat.subs.forEach(subSeat => {
          maxX = Math.max(maxX, subSeat.x)
          maxY = Math.max(maxY, subSeat.y)
        })
      })

      const maxWidth = maxX + 240 // todo remove magic number here

      if (maxWidth <= 0) return

      // now that we have the max extend, work out how much we have to zoom out
      const accountabilityChartWrapper = this.$refs.accountabilityChartWrapper
      const viewport = accountabilityChartWrapper.getBoundingClientRect()

      let xZoomExtend = viewport.width / maxWidth
      xZoomExtend -= xZoomExtend % this.zoomStep
      if (xZoomExtend > 1) xZoomExtend = 1

      this.zoomScale = xZoomExtend
      this.onSeatsUpdate()

      accountabilityChartWrapper.scrollLeft = 0
      accountabilityChartWrapper.scrollTop = 0
    },
    setData (initialData) {
      this.topLevelSeat = this.createSeat(initialData)
      this.layoutManager = new LayoutManager({ topLevelSeat: this.topLevelSeat })
      this.layoutManager.updateLayout()
      this.onSeatsUpdate()
    },
    // when someone moves the seat up at root
    // current root will become child of new root node
    //
    updateTopLevelSeat (seat) {
      const currentTopLevelSeat = this.topLevelSeat
      seat = { ...seat, subs: seat.subs || [] }
      seat.subs.push(currentTopLevelSeat)
      this.topLevelSeat = seat
      this.layoutManager.updateTopLevelSeat(seat)
    },
    createSeat (data = {}) {
      const seat = new Seat(data)
      seat.person = new Person(data.person)

      if (data.subs && data.subs.length > 0) {
        seat.subs = data.subs.map(sub => this.createSeat(sub))
      }

      return seat
    },
    addSeat (parentSeat) {
      const seatName = prompt('Seat name?')
      if (!seatName) return
      const seat = this.createSeat({ name: seatName })
      parentSeat.subs.push(seat)
      this.emitEventAndUpdateSVG()
    },
    onWindowResize () {
      this.onSeatsUpdate()
    },
    onSeatsUpdate () {
      this.layoutManager.updateLayout()
      window.requestAnimationFrame(this.startAnimateChartLinksSVG)
    },
    emitEventAndUpdateSVG (saveStatus) {
      this.$emit('seats-updated', saveStatus)
      this.onSeatsUpdate()
    },
    startAnimateChartLinksSVG () {
      const animation = setInterval(() => {
        this.onChartLinksSVGAnimationFrame()
      }, 100)
      // stop animation
      // 60 is fps for smoother transition
      setTimeout(() => clearInterval(animation), 10000 / 60)
    },
    onChartLinksSVGAnimationFrame () {
      // do the work of updating the SVG
      this.updateChartLinksSVG()
      window.requestAnimationFrame(this.onChartLinksSVGAnimationFrame)
    },
    updateChartLinksSVG () {
      const layout = this.layoutManager.getLayout()

      // We can just get the rendered elements directly, we have to
      // loop through all components recursively a build up a list
      // of the visible card elements so we can access their position
      const seatElms = this._getAllSeatDOMElements()

      // Correct zoom level
      const scrollerElm = this.$refs.accountabilityChartWrapper
      if (!scrollerElm) return ''

      const accChartInnerElm = scrollerElm.getBoundingClientRect()
      if (!accChartInnerElm) return ''

      const scrollX = scrollerElm.scrollLeft
      const scrollY = scrollerElm.scrollTop

      let strokeWidth = 3
      if (this.zoomScale < 0.8) strokeWidth = 1
      else if (this.zoomScale < 1) strokeWidth = 2
      else if (this.zoomScale > 1.2) strokeWidth = 4

      let halfCardWidth = 0
      let halfGapHeight = 0

      let html = ''
      layout.openSeats.forEach(seatId => {
        const seat = this.layoutManager.seatsById[seatId]
        if (!seat || seat.subs.length === 0) return // Seat not found

        // Get the current position of the seat (including while animating)
        const cardElm = seatElms[seat.id]
        if (cardElm === undefined) return
        const cardElmPos = cardElm.getBoundingClientRect()

        // add on the scroll
        cardElmPos.x += scrollX
        cardElmPos.y += scrollY

        // Find the start position
        const x1 = cardElmPos.x + cardElmPos.width / 2 - accChartInnerElm.x
        const y1 = cardElmPos.y + cardElmPos.height - accChartInnerElm.y
        const x2 = x1
        let y2 = y1 + halfGapHeight

        // Draw the line just below this card
        seat.subs.forEach((subSeat, index) => {
          const subSeatCardElm = seatElms[subSeat.id]
          if (!subSeatCardElm) return

          const subSeatPos = subSeatCardElm.getBoundingClientRect()
          // add on the scroll
          subSeatPos.x += scrollX
          subSeatPos.y += scrollY

          // If this is the first item, draw the line down
          if (index === 0) {
            halfCardWidth = cardElmPos.width / 2
            halfGapHeight = (subSeatPos.y - (cardElmPos.y + cardElmPos.height)) / 2

            y2 = y1 + halfGapHeight
            html += `<path d="M${x1},${y1} L${x2},${y2}" fill="none" stroke-width="${strokeWidth}" stroke="#ffb885"/>`
          }

          const x3 = subSeatPos.x + halfCardWidth - accChartInnerElm.x
          const y3 = y2
          const x4 = x3
          const y4 = y3 + halfGapHeight
          html += `<path d="M${x2},${y2} L${x3},${y3} L${x4},${y4}" fill="none" stroke-width="${strokeWidth}" stroke="#ffb885"/>`
        })
      })

      this.chartLinksSVG = html
    },
    _getAllSeatDOMElements () {
      const seatElms = {}
      const topVueSeatCard = this.$refs[`vueSeatCard${this.topLevelSeat.id}`]
      if (topVueSeatCard === undefined) return ''
      this._getSubSeatElms(seatElms, topVueSeatCard)
      return seatElms
    },
    _getSubSeatElms (seatElms, seatComponent) {
      if (seatComponent === null) return

      seatElms[seatComponent.seat.id] = seatComponent.$refs[`seatCard${seatComponent.seat.id}`]

      const subSeats = seatComponent.seat.subs || []
      subSeats.forEach(subSeat => {
        let subVueSeatCard = seatComponent.$refs[`vueSeatCard${subSeat.id}`]
        if (subVueSeatCard === undefined) return
        if (typeof subVueSeatCard === 'object') subVueSeatCard = subVueSeatCard[0]
        this._getSubSeatElms(seatElms, subVueSeatCard)
      })
    },
    toggleFilter (tag) {
      const index = this.filterByTags.indexOf(tag.id)
      index > -1 ? this.filterByTags.splice(index, 1) : this.filterByTags.push(tag.id)
    },
    expandAllSeats () {
      const closedSeats = document.querySelectorAll('.eos-chart__card--is-closed')
      closedSeats.forEach(seat => seat.click())
      this.$analytics('org_used_expand_all')
    },
    async exportToPDF () {
      // const chartWrapper = this.$refs.accountabilityChartWrapper
      const chartElm = this.$refs.accountabilityChartPDFWrapper

      // console.log('window')
      // console.log(chartWrapper.clientWidth, chartWrapper.clientHeight)
      // console.log('actual')
      // console.log(chartElm.scrollWidth, chartElm.scrollHeight)

      // console.log('scale')
      // const widthScale = parseFloat(chartWrapper.clientWidth / chartElm.scrollWidth).toFixed(2)
      // const heightScale = parseFloat(chartWrapper.clientHeight / chartElm.scrollHeight).toFixed(2)
      // console.log('width', widthScale)
      // console.log('height', heightScale)

      // const zoomScale = Math.min(widthScale * 10, heightScale * 10, 1)
      // this.zoomScale = zoomScale
      // console.log('zoom scale', zoomScale)
      // this.onSeatsUpdate()

      // setTimeout(async () => {}, 0)

      const isExporting = (status) => {
        this.isExportingToPDF = status
      }

      const fileName = `${this.chartName}.pdf`

      isExporting(true)
      await this.$nextTick()
      const dataURL = await domtoimage.toPng(chartElm, {
        quality: 1
      })

      const img = new Image()
      img.src = dataURL

      img.onload = function () {
        isExporting(false)
        // get image ratio to select orientation
        const imgRatio = this.width / this.height
        const doc = new jsPDF(imgRatio > 1 ? 'l' : 'p', 'px', 'a4')

        // center image
        const pageWidth = doc.internal.pageSize.getWidth()
        const pageHeight = doc.internal.pageSize.getHeight()
        const widthRatio = pageWidth / this.width
        const heightRatio = pageHeight / this.height
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio

        const imgWidth = this.width * ratio
        const imgHeight = this.height * ratio

        const marginX = (pageWidth - imgWidth) / 2
        const marginY = (pageHeight - imgHeight) / 2

        // add image to PDF
        doc.addImage(img, 'png', marginX, marginY, imgWidth, imgHeight)
        doc.save(fileName)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-chart {
  width: 100%;
  height: calc(100vh - 96px);
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.eos-chart__wrapper {
  width: 100%;
  height: calc(100vh - 96px);
  overflow: auto;
  cursor: move;
}

.eos-chart__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  overflow: visible;
}

::v-deep .eos-chart__pdf {
  .eos-chart__card__responsibilities {
    border-bottom-color: transparent;
    height: 198px;
  }

  .eos-chart__card__responsibilities__list {
    min-height: 188px;
  }

  .eos-chart__card__responsibilities ul {
    max-height: 164px;
    padding-bottom: 0px;
  }

  .eos-chart__card__btn-delete,
  .eos-chart__card__tags,
  .eos-chart__card__footer {
    display: none !important;
  }
}

.eos-chart__zoom-controls {
  color: #404040;
  top: 15px;
  right: 20px;
  z-index: 1;

  a {
    display: block;
    color: #404040;
    background: #fff;
    padding: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    border: 1px solid #ccc;
    box-shadow: 0 1px 4px rgba(50, 51, 52, 0.2);
    line-height: 36px;
    margin: 0 0 15px 0;
    text-decoration: none;

    &:hover {
      background-color: #eee;
    }
  }
}

a.eos-chart__zoom-controls__zoom-in {
  background:#fff url('~@/assets/accoutability-chart/zoom-in.png') no-repeat 50% 50%;
  background-size: 16px 16px;
}

a.eos-chart__zoom-controls__zoom-out {
  background:#fff url('~@/assets/accoutability-chart/zoom-out.png') no-repeat 50% 50%;
  background-size: 16px 16px;
}

a.eos-chart__zoom-controls__reset-zoom {
  line-height: 40px;
  font-size: 10px;
}

a.eos-chart__zoom-controls__fit-content {
  background:#fff url('~@/assets/accoutability-chart/reduce.png') no-repeat 50% 50%;
  background-size: 16px 16px;
}
</style>
