// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/accoutability-chart/zoom-in.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/accoutability-chart/zoom-out.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/accoutability-chart/reduce.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".eos-chart[data-v-2b5675f0]{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.eos-chart[data-v-2b5675f0],.eos-chart__wrapper[data-v-2b5675f0]{width:100%;height:calc(100vh - 96px)}.eos-chart__wrapper[data-v-2b5675f0]{overflow:auto;cursor:move}.eos-chart__svg[data-v-2b5675f0]{position:absolute;top:0;left:0;width:100px;height:100px;overflow:visible}[data-v-2b5675f0] .eos-chart__pdf .eos-chart__card__responsibilities{border-bottom-color:transparent;height:198px}[data-v-2b5675f0] .eos-chart__pdf .eos-chart__card__responsibilities__list{min-height:188px}[data-v-2b5675f0] .eos-chart__pdf .eos-chart__card__responsibilities ul{max-height:164px;padding-bottom:0}[data-v-2b5675f0] .eos-chart__pdf .eos-chart__card__btn-delete,[data-v-2b5675f0] .eos-chart__pdf .eos-chart__card__footer,[data-v-2b5675f0] .eos-chart__pdf .eos-chart__card__tags{display:none!important}.eos-chart__zoom-controls[data-v-2b5675f0]{color:#404040;top:15px;right:20px;z-index:1}.eos-chart__zoom-controls a[data-v-2b5675f0]{display:block;color:#404040;background:#fff;padding:0;border-radius:50%;width:40px;height:40px;text-align:center;border:1px solid #ccc;box-shadow:0 1px 4px rgba(50,51,52,.2);line-height:36px;margin:0 0 15px 0;text-decoration:none}.eos-chart__zoom-controls a[data-v-2b5675f0]:hover{background-color:#eee}a.eos-chart__zoom-controls__zoom-in[data-v-2b5675f0]{background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50% 50%;background-size:16px 16px}a.eos-chart__zoom-controls__zoom-out[data-v-2b5675f0]{background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50% 50%;background-size:16px 16px}a.eos-chart__zoom-controls__reset-zoom[data-v-2b5675f0]{line-height:40px;font-size:10px}a.eos-chart__zoom-controls__fit-content[data-v-2b5675f0]{background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 50% 50%;background-size:16px 16px}", ""]);
// Exports
module.exports = exports;
