<template>
  <span>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :close-on-click="!showDialog"
      max-height="315px"
      max-width="225px"
      left
      offset-x
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-hover v-slot="{ hover }">
              <span class="position-relative">
                <!-- <span
                  v-show="seatTags.length > 0"
                  class="position-absolute rounded-circle eos-chart__seat__tag-badge"
                  :style="{ backgroundColor: primaryTag.bgColor, color: primaryTag.textColor || '#fff' }"
                >
                  {{ seatTags.length }}
                </span> -->
                <v-icon
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  class="mb-2 ml-1"
                  size="16px"
                >
                  {{ hover ? 'mdi-tag-multiple' : 'mdi-tag-multiple-outline' }}
                </v-icon>
              </span>
            </v-hover>
          </template>
          <span>Tags</span>
        </v-tooltip>
      </template>

      <v-card flat>
        <div class="eos-chart__seat__tags__header">
          <v-card-title class="pb-0">
            <v-text-field
              v-model="searchTerm"
              outlined
              dense
              hide-details
              autofocus
              clearable
              height="24px"
              placeholder="Search a tag..."
            />
            <v-btn
              depressed
              x-small
              width="100%"
              class="mt-3 mb-3 py-3 mx-auto"
              @click="openDialog"
            >Add Tag</v-btn>
          </v-card-title>
          <v-divider />
        </div>

        <v-card-text class="pt-0">
          <v-layout column>
            <template v-if="filteredTags.length > 0">
              <v-flex
                v-for="tag in filteredTags"
                :key="tag.id"
                class="mt-3"
              >
                <v-chip
                  :color="tag.bgColor || ''"
                  :disabled="tag.primaryForSeatIds.includes(seatId)"
                  label
                  dark
                  small
                  class="eos-chart__seat__tag"
                  @click="toggleSelection(tag)"
                >
                  <v-expand-x-transition>
                    <v-icon v-show="tag.primaryForSeatIds.includes(seatId) || tag.selectedSeatIds.includes(seatId)" :color="tag.textColor" left small>
                      mdi-check-bold
                    </v-icon>
                  </v-expand-x-transition>

                  <span class="text-truncate text-capitalize" :style="{ color: tag.textColor }">{{ tag.title }}</span>
                </v-chip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small class="ml-2" v-on="on" @click="togglePrimary(tag)">
                      {{ tag.primaryForSeatIds.includes(seatId) ? 'mdi-star-circle' : 'mdi-star-circle-outline' }}
                    </v-icon>
                  </template>
                  <span>Mark as primary tag</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small class="ml-2" v-on="on" @click="openDialog(tag)">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-flex>
            </template>
            <p v-else class="mt-3 mb-n1 text-center">No tag found</p>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-dialog
      v-model="showDialog"
      persistent
      width="350px"
    >
      <v-card>
        <v-form
          ref="form"
          v-model="isFormValid"
        >
          <v-card-title class="px-4">{{ formTitle }}</v-card-title>
          <v-card-text class="mt-5 px-4">
            <v-text-field
              v-model="selectedTag.title"
              outlined
              dense
              autofocus
              label="Title"
              :rules="[
                v => !!v || 'Tag title is required',
                v => !tags.some(tag => (tag.title || '').toLowerCase() === v.toLowerCase() && tag.id !== selectedTag.id) || 'Tag already exists'
              ]"
            />

            <div class="mt-3">
              <p class="secondary--text mb-0">Background Color</p>
              <v-color-picker
                v-model="selectedTag.bgColor"
                dot-size="25"
                swatches-max-height="100"
                hide-inputs
                hide-canvas
                show-swatches
              />

              <p class="mt-5 secondary--text mb-0">Text Color</p>
              <v-color-picker
                v-model="selectedTag.textColor"
                dot-size="25"
                swatches-max-height="100"
                hide-inputs
                hide-canvas
                show-swatches
              />

              <v-checkbox
                v-model="selectedTag.isPrimary"
                :color="typeof selectedTag.bgColor === 'object' ? selectedTag.bgColor.hex : selectedTag.bgColor"
                true-value="true"
                label="Mark as primary tag"
                class="pt-3"
                hide-details
              />
            </div>
          </v-card-text>
          <v-card-actions class="px-4 pb-3">
            <v-btn v-if="selectedTag.id" text color="error" @click="showDialogDeleteConfirmation = true">Delete</v-btn>
            <v-spacer />
            <v-btn text @click="closeDialog">Cancel</v-btn>
            <v-btn :disabled="!isFormValid" color="success" @click="saveTag">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <AppDialogConfirmDelete
      v-if="showDialogDeleteConfirmation"
      title="Attention!"
      message="Removing this tag will remove it from all associated seats. If you want to toggle the selection for a particular seat, you can click on that tag in the list. Are you sure you want to proceed?"
      @confirm="deleteTag"
      @cancel="showDialogDeleteConfirmation = false"
    />
  </span>
</template>

<script>
export default {
  name: 'EOSAccountabilityChartSeatTags',
  props: {
    seatId: {
      type: [Number, String],
      required: true
    },
    tags: {
      type: Array,
      default: () => ([])
    },
    seatTags: {
      type: Array,
      default: () => ([])
    },
    primaryTag: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      showMenu: false,
      showDialog: false,
      showDialogDeleteConfirmation: false,
      isFormValid: true,
      searchTerm: '',
      selectedTag: {}
    }
  },
  computed: {
    searchTermLowerCase () {
      return (this.searchTerm || '').toLowerCase()
    },
    filteredTags () {
      let tags = this.tags.slice()

      // show only matching tag(s) if user is searching
      if (this.searchTermLowerCase) {
        tags = tags.filter(tag => tag.title.toLowerCase().includes(this.searchTermLowerCase))
      }

      return tags
    },
    formTitle () {
      return `${this.selectedTag.id ? 'Edit' : 'Add'} Tag`
    }
  },
  methods: {
    async togglePrimary (tag) {
      // remove existing
      const currPrimary = this.tags.find(tag => tag.primaryForSeatIds.indexOf(this.seatId) > -1)
      if (currPrimary && tag.id !== currPrimary.id) await this.toggleSeatId(currPrimary.primaryForSeatIds)

      // add to selected if it's not
      await this.toggleSeatId(tag.primaryForSeatIds)

      this.emitUpdate()
    },
    async toggleSelection (tag) {
      await this.toggleSeatId(tag.selectedSeatIds)
      this.emitUpdate()
    },
    toggleSeatId (arr) {
      const index = arr.indexOf(this.seatId)
      index > -1 ? arr.splice(index, 1) : arr.push(this.seatId)
      return Promise.resolve()
    },
    openDialog (tag = {}) {
      this.selectedTag = {
        id: null,
        title: '',
        bgColor: '#000000',
        textColor: '#ECEFF1',
        primaryForSeatIds: [],
        selectedSeatIds: [],
        ...tag,
        isPrimary: tag.id && tag.primaryForSeatIds.includes(this.seatId)
      }

      this.showDialog = true
    },
    closeDialog () {
      this.showDialog = false
    },
    async saveTag () {
      // check if it's marked as primary
      if (this.selectedTag.isPrimary) await this.togglePrimary(this.selectedTag)
      delete this.selectedTag.isPrimary

      const tagId = this.selectedTag.id
      const index = tagId ? this.tags.findIndex(tag => tag.id === tagId) : -1
      index > -1 ? this.tags[index] = this.selectedTag : this.tags.push({
        ...this.selectedTag,
        id: this.$helpers.generateUniqueId()
      })

      this.closeDialog()
      this.emitUpdate()
    },
    deleteTag () {
      const index = this.tags.findIndex(tag => tag.id === this.selectedTag.id)
      if (index === -1) return

      this.tags.splice(index, 1)
      this.emitUpdate()

      this.showDialogDeleteConfirmation = false
      this.closeDialog()
    },
    emitUpdate () {
      this.$emit('update-tags', this.tags)
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-chart__seat__tags__header {
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 1;
}

.eos-chart__seat__tag {
  width: 145px;

  &.v-chip--disabled {
    opacity: 1;
  }
}

// .eos-chart__seat__tag-badge {
//   top: -14px;
//   right: -4px;
//   background: #757575;
//   width: 14px;
//   height: 14px;
//   color: white;
//   text-align: center;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
</style>
