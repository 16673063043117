<template>
  <v-tooltip top max-width="375px">
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="info" :x-small="!size" :size="size" :class="iconClass" color="secondary">mdi-information-outline</v-icon>
        <a v-else href="https://teamwork.grsm.io/instantagencytools" target="_blank" class="text-decoration-none" :class="linkClass">
          <v-icon size="11px" class="tw-branding__icon" v-text="'$vuetify.icons.tw-teamwork'" />
          <strong>Teamwork</strong>
        </a>
      </span>
    </template>
    <ul class="py-3 pl-5">
      <li class="mb-2">
        Teamwork is the project management software that delights your customers and scales your business. Teamwork helps businesses to deliver projects in scope and on time and budget.
      </li>
      <li class="mb-2">
        In combination with InstantAgencyTools and syncing your weekly to-dos, Teamwork will keep your team on track and focused on what matters most.
      </li>
      <li>
        Within the InstantAgencyTools Key Priorities tool you can sync all your key priorities with any selected task list in Teamwork and jumpstart the execution on the most impactful priorities in your business.
      </li>
    </ul>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AppTeamworkBranding',
  props: {
    info: {
      type: Boolean,
      default: false
    },
    linkClass: {
      type: String,
      default: 'secondary--text'
    },
    size: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.tw-branding__icon {
  margin: 0 2px 2px;
}
</style>
