<template>
  <div class="px-1">
    <v-toolbar dense flat class="eos-app__toolbar">
      <div v-if="entityName" class="d-flex align-center eos-app__name">
        <div
          v-if="!isEditingName"
          class="d-block text-subtitle-2 text-sm-h6 py-1"
          :class="{ 'cursor-pointer': !disableRename }"
          @click="editEntityName"
        >
          {{ entityName }}
        </div>

        <form v-else @submit.prevent="isEditingName = false">
          <input
            ref="entityNameInput"
            :value="entityName"
            type="text"
            size="20"
            class="text-subtitle-2 text-sm-h6 px-2 py-1"
            @change="updateEntityName"
            @blur="isEditingName = false"
          />
        </form>
        <slot name="left"></slot>
      </div>

      <v-spacer />

      <slot name="right"></slot>

      <template v-if="!hideDefaultActions">
        <AppVisibilityBtn
          class="hidden-print"
          :entity="entity"
          :visibility="visibility"
          @click="togglePrivacy"
        />

        <AppUserPermissionList
          ref="permissions"
          class="hidden-print"
          :tw-sync-options="twSyncOptions"
          :entity="entity"
          :entity-name="entityName"
          :visibility="visibility"
          :domains="domains"
          :users="users"
          :logged-in-user="loggedInUser"
          :sync-handles-with-teamwork="!!(twSyncOptions.accessToken && twSyncOptions.projectId && twSyncOptions.tasklistId)"
          @make-private="togglePrivacy"
          @update-permissions="$emit('update-permissions', $event)"
          @set-message="$emit('set-message', $event)"
        />

        <v-menu bottom left offset-y min-width="165px">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="ml-n2 hidden-print">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense class="hidden-print">
            <v-list-item v-if="!disableRename" @click="editEntityName">
              <v-list-item-title>Rename</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="printable" @click="$emit('print')">
              <v-list-item-title>Print</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!disableDelete" @click="confirmDelete">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!disableImportExport" @click="$emit('open-import-export-dialog')">
              <v-list-item-title>Import/Export Data</v-list-item-title>
            </v-list-item>
            <AppSyncOptions
              v-if="allowSync"
              :tw-sync-options="twSyncOptions"
              @show-sync-options="$emit('show-sync-options')"
              @initialize-tw-oauth-process="$emit('initialize-tw-oauth-process')"
              @save-sync-options="$emit('save-sync-options', $event)"
            />
          </v-list>
        </v-menu>
      </template>
    </v-toolbar>

    <AppDialogConfirmDelete
      v-if="showDialogConfirmDelete"
      :message="`You want to delete this ${entity}?`"
      @confirm="deleteEntity"
      @cancel="showDialogConfirmDelete = false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppVisibilityBtn from '@/components/shared/visibility/AppVisibilityBtn'
import AppUserPermissionList from '@/components/shared/permissions/AppUserPermissionList'
import AppSyncOptions from '@/components/shared/sync/AppSyncOptions'

export default {
  name: 'AppPageToolbar',
  components: {
    AppVisibilityBtn,
    AppUserPermissionList,
    AppSyncOptions
  },
  props: {
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    users: {
      type: Array,
      default: () => ([])
    },
    domains: {
      type: Array,
      default: () => ([])
    },
    visibility: {
      type: String,
      default: ''
    },
    entity: {
      type: String,
      default: ''
    },
    entityName: {
      type: String,
      default: ''
    },
    hideDefaultActions: {
      type: Boolean,
      default: false
    },
    printable: {
      type: Boolean,
      default: false
    },
    disableRename: {
      type: Boolean,
      default: false
    },
    disableDelete: {
      type: Boolean,
      default: false
    },
    disableImportExport: {
      type: Boolean,
      default: false
    },
    allowSync: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isEditingName: false,
      showDialogConfirmDelete: false
    }
  },
  methods: {
    ...mapActions(['setMessage']),
    async editEntityName () {
      if (this.disableRename) return
      this.isEditingName = true
      await this.$nextTick()
      this.$refs.entityNameInput.focus()
    },
    updateEntityName (event) {
      if (!event.target.value) return
      this.$emit('update-name', event.target.value)
    },
    togglePrivacy () {
      if (this.loggedInUser.isAnonymous) {
        return this.setMessage({
          type: 'error',
          message: `Please sign in to make this ${this.entity} private.`
        })
      }
      const privacy = this.visibility === 'private' ? 'public' : 'private'
      this.$emit('update-privacy', privacy)
    },
    managePermissions () {
      this.$refs.permissions.openDialogUsersList()
    },
    openDialogAddUser () {
      this.$refs.permissions.showDialogAddEditUser()
    },
    confirmDelete () {
      this.showDialogConfirmDelete = true
    },
    deleteEntity () {
      this.$emit('delete')
    }
  }
}
</script>

<style type="scss" scoped>
.eos-app__toolbar {
  border-bottom: 1px solid #eee;
}

.eos-app__name {
  width: 50%;
}
</style>
